@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

$font: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

//Font weights
$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
$fw-black: 800;

//Font sizes
$fs-base-mobile: 14px;
$fs-base-desktop: 16px;

$fs-xs: 12px;
$fs-s: 14px;
$fs-base: 16px;
$fs-m: 18px;
$fs-l: 20px;
$fs-xl: 24px;
$fs-xxl: 32px;
$fs-xxxl: 42px;
$fs-xxxxl: 64px;
$fs-h1: 84px;

//Line heights
$lh-base: 1.2;
$lh-sm: 1.28;
$lh-lg: 1.65;

// layout
$navbar-height: 100px;

//Border radius
$br-circle: 999px;
$br-button: 36px;
$br-card: 30px;
$br-input: 50px;

//colors
$color-primary-base: #00539c;
$color-primary-light: #d1eaff;

$color-secondary-base: #F5CB5C;

$color-error-base: #BA0E0E;

$color-neutral-base: #242423;
$color-neutral-text: #333533;

$color-black: #000000;
$color-white: #FFF;

$color-background-base: #fff;

// breakpoints
$breakpoint-sm: 576px;
$breakpoint-sm-max: 575px;
$breakpoint-md: 768px;
$breakpoint-md-max: 767px;
$breakpoint-lg: 992px;
$breakpoint-lg-max: 991px;
$breakpoint-xl: 1200px;
$breakpoint-xl-max: 1199px;
$breakpoint-xxl: 1400px;

@keyframes fadeInLeft {
    0%{
        transform: translateX(-150%);
    }

    100%{
        transform: translateX(0%);
    }
}