.navbar {
  position: sticky;
  z-index: 999;
  top: 0;
  box-shadow: 0px 6px 10px #00000029;
  background-color: $color-white;
  min-height: 65px;
  transition: transform 0.5s ease;

  &.out {
    transform: translateY(-100%);
  }

  @media screen and (min-width: $breakpoint-md) {
    min-height: 90px;
  }

  &-brand {
    img {
      max-width: 170px;
    }
  }

  &-toggler {
    border: none;
    background-color: $color-primary-light;
    border-radius: 999px;
    padding: 8px;
    .icon {
      &::before {
        content: "";
        display: flex;
        width: 15px;
        height: 15px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Ctitle/%3E%3Cg id='cross'%3E%3Cline class='cls-1' x1='7' x2='25' y1='7' y2='25'/%3E%3Cline class='cls-1' x1='7' x2='25' y1='25' y2='7'/%3E%3C/g%3E%3C/svg%3E");
      }
    }

    &.collapsed {
      padding: 8px 16px;
      .icon {
        &::before {
          content: "Menu";
          display: inline;
          background-image: none;
        }
      }
    }
    &:focus {
      outline: 0;
      box-shadow: none;
      border: none;
    }
  }

  .nav {
    &-item {
      margin-bottom: 30px;
      @media screen and (min-width: $breakpoint-xl) {
        padding: 10px 24px;
        margin-bottom: 0px;
      }
    }
    &-link {
      font-size: $fs-xxl;
      border-radius: 999px;
      padding: 5px 10px;
      color: $color-white;
      @media screen and (min-width: $breakpoint-xl) {
        color: $color-primary-base;
        font-size: $fs-l;

        &:hover {
          color: $color-primary-base;
          background-color: $color-primary-light;
        }
        &:focus {
          color: $color-primary-base;
          background-color: $color-primary-light;
        }
      }
    }
  }

  &-collapse {
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    background: $color-primary-base;
    text-align: right;
    padding: 38px 15px 20px;
    height: calc(100vh - 65px);
    @media screen and (min-width: $breakpoint-sm) {
      left: auto;
      width: 100%;
      max-width: 400px;
      height: auto;
    }
    @media screen and (min-width: $breakpoint-md) {
      left: auto;
      width: 100%;
      max-width: 400px;
      top: 90px;
    }
    @media screen and (min-width: $breakpoint-xl) {
      max-width: 100%;
      position: static;
      background-color: $color-white;
      text-align: left;
      padding: 0px;
    }
  }

  .dropdown {
    &-menu {
      padding: 0px;
      border: none;
      background: $color-primary-base;
      top: 52px;

      @media screen and (min-width: $breakpoint-xl) {
        padding: 38px 26px 48px 38px;
        border-radius: 0 0 $br-card $br-card;
        background-color: $color-white;
        display: none;
        opacity: 0;
      }
    }

    @media screen and (min-width: $breakpoint-xl) {
      &:hover {
        .nav-link {
          background-color: $color-primary-light;
        }
        > .dropdown-menu {
          display: block;
          animation: fadeShow 0.6s ease 0.1s 1 forwards;
        }
      }
    }

    &-item {
      background: $color-primary-base;
      text-align: right;
      padding: 0;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      @media screen and (min-width: $breakpoint-xl) {
        text-align: left;
        background-color: $color-white;
        transform: translateX(-12px);
      }
    }

    &-link {
      background: $color-primary-base;
      padding: 0;
      border-radius: 999px;
      color: $color-primary-light;
      font-size: $fs-xl;
      font-weight: $fw-light;
      transition: all 0.3s ease-in;
      @media screen and (min-width: $breakpoint-xl) {
        padding: 5px 10px;
        color: $color-primary-base;
        background: linear-gradient(
          to right,
          $color-white 0%,
          $color-white 50%,
          $color-primary-light 50%,
          $color-primary-light 100%
        );
        background-size: 200% 100%;
        background-position: 0%;
        &:hover {
          background-position: 100%;
        }
      }
    }

    .myArrow {
      transition: all 0.3s ease;
    }

    &.show {
      .myArrow {
        transform: rotate(180deg);
        transition: all 0.3s ease;
      }
    }
  }
}

.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: $color-white;
  @media screen and (min-width: $breakpoint-xl) {
    color: $color-primary-base;
  }
}

@keyframes fadeShow {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  99% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    z-index: 0;
  }
}

.upNav {
  padding: 0.5rem 0 !important;
  &_text {
    text-transform: none !important;
    font-size: 0.9375rem;
    line-height: 1.5;
  }
}
